<template>
  <div class="teamMember" v-if="localmgdc && localmgdc.id">
    <p class="text name">ID: {{ localmgdc.id }}</p>
    <p class="text name">Has breeded: {{ localmgdc.hasBreed ? "Yes" : "No" }}</p>
    <p class="text name" style="margin-bottom: 10px">
      Is Listed to breed: {{ localmgdc.isListed ? "Yes" : "No" }}
    </p>
    <div class="picContainer">
      <div class="img-blc">
        <img
          class="pic"
          :src="`https://metagolddiggerclub.com/img/thumbnails/${localmgdc.id}.png`"
        />
        <div class="tooltip">
          <a @click="toggle">
            <i class="fas fa-pencil"></i>
          </a>
          <span class="tooltiptext">Edit biography</span>
        </div>
      </div>
    </div>
    <button class="connectButton" @click="showListintChoices" v-show="!listingChoices">
      {{ localmgdc.isListed ? "Already Listed" : "List on Kinder-Ape" }}
    </button>
    <div v-show="listingChoices" class="listing-choices">
      <div class="breed-choice" @click="list">ETH</div>
      <div class="breed-choice" @click="handleListWithToken">MGDC Token</div>
      <i class="fas fa-times-circle close-choice" @click="closeBreed"></i>
    </div>
    <div class="modal-window" v-show="show">
      <div>
        <a title="Close" class="modal-close" @click="show = false">
          <i class="fas fa-times-circle close"></i>
        </a>
        <div class="form-group">
          <label>Biography :</label>
          <textarea
            v-model="biography"
            placeholder="type your biography here "
          ></textarea>
        </div>
        <button class="btn-save" @click="save">Save</button>
      </div>
    </div>
    <div class="modal-window" v-show="showConfimation">
      <div>
        <a title="Close" class="modal-close" @click="showConfimation = false">
          <i class="fas fa-times-circle close"></i>
        </a>
        <svg class="spinner" viewBox="0 0 50 50" v-show="loading">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
        <button class="btn-save" v-show="showApprove" @click="approve">Aprove</button>
        <button class="btn-save" v-show="showListToken" @click="listToken">List</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
var Web3 = require("web3");
const web3 = new Web3(window.ethereum);

export default {
  name: "BreedCard",
  props: ["mgdc", "contract", "contractMGDCStake", "toggleLoading", "listMgdc", "target"],
  computed: {
    ...mapGetters(["curremgdc", "account"]),
  },
  watch: {
    curremgdc(val, old) {
      if (val != old && val) {
        this.biography = val.biography;
      }
    },
  },
  data() {
    return {
      style: "",
      localmgdc: null,
      show: false,
      showListToken: false,
      showApprove: false,
      showConfimation: false,
      biography: null,
      listingChoices: false,
      loading: false,
    };
  },
  async mounted() {
    this.localmgdc = { ...this.mgdc };
  },
  methods: {
    async listToken() {
      this.loading = true;
      this.$store.commit("SET_PROFILE_IS_LOADING", true);

      try {
        this.showConfimation = true;
        this.showApprove = false;
        this.showListToken = true;
        await this.contract.methods.listBreedingWithMGDCToken(this.localmgdc.id).send({
          from: this.account,
        });
        this.loading = false;
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
        this.showConfimation = false;
        this.showApprove = false;
        this.showListToken = false;
      } catch (error) {
        this.loading = false;
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
      }
    },
    async approve() {
      this.loading = true;
      this.$store.commit("SET_PROFILE_IS_LOADING", true);
      this.showConfimation = true;
      this.showApprove = true;
      this.showListToken = false;
      try {
        const breedPrice = await this.contract.methods.breedPrice().call();
        await this.contractMGDCStake.methods
          .approve(
            process.env.VUE_APP_BREED_HAPE,
            web3.utils.toWei(breedPrice.toString(), "ether")
          )
          .send({
            from: this.account,
          });
        const allowance = await this.contractMGDCStake.methods
          .allowance(this.account, process.env.VUE_APP_BREED_HAPE)
          .call();
        if (allowance >= breedPrice) {
          this.loading = false;
          this.$store.commit("SET_PROFILE_IS_LOADING", false);
          this.showConfimation = true;
          this.showApprove = false;
          this.showListToken = true;
        }
      } catch (error) {
        this.loading = false;
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
      }
    },
    async handleListWithToken() {
      if (this.localmgdc.isListed) return;
      this.loading = true;
      this.$store.commit("SET_PROFILE_IS_LOADING", true);
      this.showConfimation = false;
      this.showApprove = false;
      this.showListToken = false;

      try {
        const breedPrice = await this.contract.methods.breedPrice().call();
        const allowance = await this.contractMGDCStake.methods
          .allowance(this.account, process.env.VUE_APP_BREED_HAPE)
          .call();
        if (allowance >= breedPrice) {
          await this.contract.methods.listBreedingWithMGDCToken(this.localmgdc.id).send({
            from: this.account,
          });
        } else {
          this.loading = false;
          this.$store.commit("SET_PROFILE_IS_LOADING", false);
          this.showConfimation = true;
          this.showApprove = true;
          this.showListToken = false;
        }
      } catch (error) {
        this.loading = false;
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
      }
    },
    closeBreed() {
      this.listingChoices = null;
    },
    showListintChoices() {
      if (this.localmgdc.isListed) return;
      if (this.target === "BAYC") {
        this.list();
      } else {
        this.listingChoices = true;
      }
    },
    async toggle() {
      this.$store.commit("SET_PROFILE_IS_LOADING", true);
      await this.$store.dispatch("getMgdc", this.localmgdc.id);
      this.show = true;
      this.$store.commit("SET_PROFILE_IS_LOADING", false);
    },
    async save() {
      this.$store.commit("SET_PROFILE_IS_LOADING", true);
      await this.$store.dispatch("upadeteMgdc", {
        biography: this.biography,
        id: this.localmgdc.id,
      });
      this.$store.commit("SET_PROFILE_IS_LOADING", false);
      this.show = false;
    },
    goToExternal(url) {
      window.open(url);
    },
    async list() {
      if (this.localmgdc.isListed) return;
      try {
        this.$store.commit("SET_PROFILE_IS_LOADING", true);
        await this.contract.methods.listBreeding(this.localmgdc.id).send({
          from: this.account,
          value: "250000000000000000",
        });
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
      } catch (error) {
        this.$store.commit("SET_PROFILE_IS_LOADING", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close-choice {
  margin-top: -14px;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: pink;
  }
}

.listing-choices {
  margin-top: 30px;
}
.breed-choice {
  font-size: 20px;
  background-color: #e53261;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  opacity: 0.9;
  text-align: center;
  transition: all 100ms ease-in-out;
  display: inline;
  justify-content: center;

  padding-right: 10px;
  padding-left: 10px;
  margin-left: 2px;
  color: white !important;
  &:hover {
    filter: drop-shadow(0px 0px 5px $cerise-red);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.teamMember {
  transition: all 500ms ease-in-out;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
  width: 250px;
  > * {
    transition: all 100ms ease-in-out;
  }
  //border: 1px solid blue;
}

.name {
  font-size: 18px;
  line-height: 28px;
  margin: auto;
  margin-bottom: -5px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: $white;
  font-family: Jumble;
}

.btn-save {
  border: 1px solid #821246;
  background: #821246;
  padding: 5px 30px;
}

.form-group {
  text-align: left;
  label {
    text-align: left;
  }
}

textarea {
  margin-top: 15px;
  width: 100%;
  border: 1px solid #821246;
  background: #821246;
  padding: 7px;
  outline: none;
  color: white;
  resize: none;
  height: 150px;
  margin-bottom: 15px;
}

::placeholder {
  color: white;
  font-family: var(--font-family-acme);
}
.img-blc {
  max-width: 200px;
  height: 200px;
  margin: auto;
  position: relative;
  .tooltip {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
.pic {
  width: 200px;
  height: 200px;
  border-radius: 500px;
  object-fit: cover;
  transition: all 100ms ease-in-out;
  //box-shadow: 0px 0px 5px 0px #ffffff;
  filter: drop-shadow(0px 0px 5px #ffffff);
}
.connectButton {
  margin-top: 25px;
  padding: 15px 15px;
  font-size: 15px;
}
@media screen and (max-width: $layout-breakpoint-xxlarge) {
  .connectButton {
    margin-top: 25px;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 15px;
    border: 4px solid pink;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .teamMember {
    margin-bottom: 50px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .img-blc {
    max-width: 150px;
    height: 150px;
  }
  .pic {
    width: 150px;
    height: 150px;
  }
  .name {
    width: 200px;
    font-size: 18px;
  }
  .fa {
    font-size: 22px;
  }
  .fab {
    font-size: 25px;
  }
  .logoContainer2 {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.85;
  }
  .socialsContainer2 {
    margin-left: 10px;
    margin-top: 0px;
  }
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  & > div {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: linear-gradient(180deg, #e56932 0%, #ba3474 83.74%, #9b3782 100%);
    box-shadow: 0 0 20px #e56932;
  }
  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: #aaa;
  font-size: 80%;
  position: absolute;
  right: -24px;
  text-align: center;
  top: -10px;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: black;
  }
  .close {
    color: white;
  }
}

.modal-window {
  z-index: 1000;
  & > div {
    border-radius: 1rem;
  }
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.tooltip {
  position: relative;
  display: inline-block;
  z-index: 999;
  a {
    cursor: pointer;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #aa3c75;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
